import React, { useEffect, useState } from 'react'
import logo from '../../logo.svg'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import { getApp } from 'firebase/app'
import { UserDoc } from '../../../shared/firestoreDocs'
import { BasicDraft } from '../../../shared/models'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectEmail } from '../../store/user/userSlice'

export const loader = async () => {}

const DraftsList: React.FC = () => {
    const userEmail = useSelector(selectEmail)
    // const [drafts, setDrafts] = useState<BasicDraft[] | undefined>(getInitDrafts());
    const [drafts, setDrafts] = useState<BasicDraft[] | undefined>(undefined)

    useEffect(() => {
        console.log('userEmail', userEmail)
        if (!userEmail) return

        const db = getFirestore(getApp())
        const docRef = doc(db, 'users', userEmail)

        return onSnapshot(docRef, draftDoc => {
            if (!draftDoc.exists()) {
                console.log('User doc not found')
                return
            }

            const userDoc = draftDoc.data() as UserDoc
            setDrafts(userDoc.drafts)
        })
    }, [userEmail])

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>Drafts List</p>
                {drafts && drafts.length ? (
                    <ul>
                        {drafts.map(draft => (
                            <li key={`${draft.leagueId}-${draft.seasonId}`}>
                                <Link to={`${draft.leagueId}-${draft.seasonId}`}>{draft.leagueName}</Link>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No drafts</p>
                )}
            </header>
        </div>
    )
}

export default DraftsList

// const getInitDrafts = () => {
//     if (process.env.NODE_ENV !== 'development') {
//         return undefined
//     }
//
//     const demoData: BasicDraft[] = [{seasonId: '2022', leagueId: '12345678', leagueName: 'Testing League'}]
//     return demoData
// }
