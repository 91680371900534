import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'

// Define a type for the slice state
interface UserState {
    email?: string
}

// Define the initial state using that type
const initialState: UserState = {
    email: undefined,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<string>) => {
            state.email = action.payload
        },
    },
})

export const { setUser } = userSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectEmail = (state: RootState) => state.user?.email

export default userSlice.reducer
