/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AthleteAllDraftStatsDto {
    /** @format uuid */
    id: string
    sportLeagueId: string
    sportTeamId: string
    firstName: string
    lastName: string
    fullName: string
    shortName: string
    slug: string

    /**
     * @format decimal
     * @example 1.345
     */
    weightLbs: string

    /**
     * @format decimal
     * @example 1.345
     */
    heightIn: string
    dateOfBirth: number

    /** @format int32 */
    debutYear?: number | null
    position: string
    jersey?: string | null

    /** @format int32 */
    yearsExperience: number

    /** @format int32 */
    draftYear?: number | null

    /** @format int32 */
    draftRound?: number | null

    /** @format int32 */
    draftSelection?: number | null
    headshot?: string | null
    isActive: boolean
    athleteNflStats: AthleteNflStatsDto[]
    sportTeam: SportTeamDto
    teamMates: AthleteDto[]
}

export interface AthleteDto {
    /** @format uuid */
    id: string
    sportLeagueId: string
    sportTeamId: string
    firstName: string
    lastName: string
    fullName: string
    shortName: string
    slug: string

    /**
     * @format decimal
     * @example 1.345
     */
    weightLbs: string

    /**
     * @format decimal
     * @example 1.345
     */
    heightIn: string
    dateOfBirth: number

    /** @format int32 */
    debutYear?: number | null
    position: string
    jersey?: string | null

    /** @format int32 */
    yearsExperience: number

    /** @format int32 */
    draftYear?: number | null

    /** @format int32 */
    draftRound?: number | null

    /** @format int32 */
    draftSelection?: number | null
    headshot?: string | null
    isActive: boolean
    athleteNflStats?: AthleteNflStatsDto[] | null
}

export interface AthleteNflStatsDto {
    /** @format uuid */
    athleteId: string

    /** @format int32 */
    seasonYear: number

    /** @format int32 */
    rank: number

    /**
     * @format decimal
     * @example 1.345
     */
    averageDraftPosition: string

    /**
     * @format decimal
     * @example 1.345
     */
    averageDraftPositionPpr: string

    /** @format int32 */
    byeWeek: number

    /** @format int32 */
    gamesPlayed: number

    /** @format int32 */
    gamesStarted: number

    /** @format int32 */
    touchdowns: number

    /** @format int32 */
    fumbles: number

    /** @format int32 */
    fumblesLost: number

    /**
     * @format decimal
     * @example 1.345
     */
    extraPointsAttempted: string

    /**
     * @format decimal
     * @example 1.345
     */
    extraPointsMade: string

    /**
     * @format decimal
     * @example 1.345
     */
    fieldGoalPercentage: string

    /**
     * @format decimal
     * @example 1.345
     */
    fieldGoalsAttempted: string

    /**
     * @format decimal
     * @example 1.345
     */
    fieldGoalsMade: string

    /**
     * @format decimal
     * @example 1.345
     */
    fumblesRecovered: string

    /**
     * @format decimal
     * @example 1.345
     */
    interceptions: string

    /**
     * @format decimal
     * @example 1.345
     */
    pointsAllowed: string

    /**
     * @format decimal
     * @example 1.345
     */
    sacks: string

    /**
     * @format decimal
     * @example 1.345
     */
    specialTeamsTouchdowns: string

    /**
     * @format decimal
     * @example 1.345
     */
    tacklesForLoss: string

    /** @format int32 */
    passingAttempts: number

    /** @format int32 */
    passingCompletions: number

    /**
     * @format decimal
     * @example 1.345
     */
    passingYards: string

    /**
     * @format decimal
     * @example 1.345
     */
    passingYardsPerAttempt: string

    /**
     * @format decimal
     * @example 1.345
     */
    passingTouchdowns: string

    /**
     * @format decimal
     * @example 1.345
     */
    passingInterceptions: string

    /**
     * @format decimal
     * @example 1.345
     */
    receivingTargets: string

    /**
     * @format decimal
     * @example 1.345
     */
    receptions: string

    /**
     * @format decimal
     * @example 1.345
     */
    receivingYards: string

    /**
     * @format decimal
     * @example 1.345
     */
    receivingYardsPerReception: string

    /**
     * @format decimal
     * @example 1.345
     */
    receivingTouchdowns: string

    /**
     * @format decimal
     * @example 1.345
     */
    rushingAttempts: string

    /**
     * @format decimal
     * @example 1.345
     */
    rushingYards: string

    /**
     * @format decimal
     * @example 1.345
     */
    rushingYardsPerAttempt: string

    /**
     * @format decimal
     * @example 1.345
     */
    rushingTouchdowns: string
}

export interface SeasonDto {
    id: string
    sportLeagueId: string
    name: string
    isActive: boolean
    sportTeams?: SportTeamDto[] | null
}

export interface SportLeagueDto {
    id: string
    name: string
    logo?: string | null
    activeSeason: SeasonDto
    sportTeams?: SportTeamDto[] | null
}

export interface SportTeamDto {
    id: string
    sportLeagueId: string
    name: string
    shortName: string
    abbreviation: string
    slug: string
    logo?: string | null
    athletes?: AthleteDto[] | null
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean
    /** request path */
    path: string
    /** content type of request body */
    type?: ContentType
    /** query params */
    query?: QueryParamsType
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseFormat
    /** request body */
    body?: unknown
    /** base url */
    baseUrl?: string
    /** request cancellation token */
    cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
    baseUrl?: string
    baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
    securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
    customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
    data: D
    error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
    Json = 'application/json',
    FormData = 'multipart/form-data',
    UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
    public baseUrl: string = ''
    private securityData: SecurityDataType | null = null
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
    private abortControllers = new Map<CancelToken, AbortController>()
    private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

    private baseApiParams: RequestParams = {
        credentials: 'same-origin',
        headers: {},
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    }

    constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
        Object.assign(this, apiConfig)
    }

    public setSecurityData = (data: SecurityDataType | null) => {
        this.securityData = data
    }

    protected encodeQueryParam(key: string, value: any) {
        const encodedKey = encodeURIComponent(key)
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
    }

    protected addQueryParam(query: QueryParamsType, key: string) {
        return this.encodeQueryParam(key, query[key])
    }

    protected addArrayQueryParam(query: QueryParamsType, key: string) {
        const value = query[key]
        return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
    }

    protected toQueryString(rawQuery?: QueryParamsType): string {
        const query = rawQuery || {}
        const keys = Object.keys(query).filter(key => 'undefined' !== typeof query[key])
        return keys
            .map(key =>
                Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key),
            )
            .join('&')
    }

    protected addQueryParams(rawQuery?: QueryParamsType): string {
        const queryString = this.toQueryString(rawQuery)
        return queryString ? `?${queryString}` : ''
    }

    private contentFormatters: Record<ContentType, (input: any) => any> = {
        [ContentType.Json]: (input: any) =>
            input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
        [ContentType.FormData]: (input: any) =>
            Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key]
                formData.append(
                    key,
                    property instanceof Blob
                        ? property
                        : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`,
                )
                return formData
            }, new FormData()),
        [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
    }

    protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {}),
            },
        }
    }

    protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
        if (this.abortControllers.has(cancelToken)) {
            const abortController = this.abortControllers.get(cancelToken)
            if (abortController) {
                return abortController.signal
            }
            return void 0
        }

        const abortController = new AbortController()
        this.abortControllers.set(cancelToken, abortController)
        return abortController.signal
    }

    public abortRequest = (cancelToken: CancelToken) => {
        const abortController = this.abortControllers.get(cancelToken)

        if (abortController) {
            abortController.abort()
            this.abortControllers.delete(cancelToken)
        }
    }

    public request = async <T = any, E = any>({
        body,
        secure,
        path,
        type,
        query,
        format,
        baseUrl,
        cancelToken,
        ...params
    }: FullRequestParams): Promise<HttpResponse<T, E>> => {
        const secureParams =
            ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
            {}
        const requestParams = this.mergeRequestParams(params, secureParams)
        const queryString = query && this.toQueryString(query)
        const payloadFormatter = this.contentFormatters[type || ContentType.Json]
        const responseFormat = format || requestParams.format

        return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
            ...requestParams,
            headers: {
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
                ...(requestParams.headers || {}),
            },
            signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
            body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
        }).then(async response => {
            const r = response as HttpResponse<T, E>
            r.data = null as unknown as T
            r.error = null as unknown as E

            const data = !responseFormat
                ? r
                : await response[responseFormat]()
                      .then(data => {
                          if (r.ok) {
                              r.data = data
                          } else {
                              r.error = data
                          }
                          return r
                      })
                      .catch(e => {
                          r.error = e
                          return r
                      })

            if (cancelToken) {
                this.abortControllers.delete(cancelToken)
            }

            if (!response.ok) throw data
            return data
        })
    }
}

/**
 * @title DraftDay.PublicApi, Version=1.0.0.0, Culture=neutral, PublicKeyToken=null
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
    v1 = {
        /**
         * No description
         *
         * @tags Athletes
         * @name GetByName
         * @request GET:/v1/{SportLeagueId}/athletes/getbyname
         */
        getByName: (
            sportLeagueId: string,
            query: { AthleteName: string; Position: string },
            params: RequestParams = {},
        ) =>
            this.request<AthleteDto, any>({
                path: `/v1/${sportLeagueId}/athletes/getbyname`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags Athletes
         * @name GetAllDraftStatsByName
         * @request GET:/v1/{SportLeagueId}/athletes/getalldraftstatsbyname
         */
        getAllDraftStatsByName: (
            sportLeagueId: string,
            query: { AthleteName: string; Position: string },
            params: RequestParams = {},
        ) =>
            this.request<AthleteAllDraftStatsDto, any>({
                path: `/v1/${sportLeagueId}/athletes/getalldraftstatsbyname`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags SportLeagues
         * @name GetById
         * @request GET:/v1/sportleagues/getbyid
         */
        getById: (query: { SportLeagueId: string }, params: RequestParams = {}) =>
            this.request<SportLeagueDto, any>({
                path: `/v1/sportleagues/getbyid`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),

        /**
         * No description
         *
         * @tags SportTeams
         * @name GetAllBySportLeague
         * @request GET:/v1/sportteams/getallbysportleague
         */
        getAllBySportLeague: (query: { SportLeagueId: string }, params: RequestParams = {}) =>
            this.request<SportTeamDto[], any>({
                path: `/v1/sportteams/getallbysportleague`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params,
            }),
    }
}
