import { AnyAction, ThunkAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { setUser } from './userSlice'

// Thunks
export const checkUser = (): ThunkAction<void, RootState, unknown, AnyAction> => async dispatch => {
    console.log('Checking user auth status')

    dispatch(setUser('vallance.charles@gmail.com'))
}
