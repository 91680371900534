import React from 'react'
import logo from './logo.svg'
import './App.css'

const App: React.FC = () => {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>draftday.io</p>
                {/* <Link to={} */}
            </header>
        </div>
    )
}

export default App
