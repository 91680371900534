import React, { useCallback } from 'react'
import logo from '../../logo.svg'
import { getApp } from 'firebase/app'
import { useSelector } from 'react-redux'
import { selectEmail } from '../../store/user/userSlice'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { Button } from '@mui/material'

const AdminDashboard: React.FC = () => {
    const userEmail = useSelector(selectEmail)

    const scrapePlayers = useCallback(async () => {
        const functions = getFunctions(getApp())
        const scrapePlayersFunc = httpsCallable<unknown, number>(functions, 'scrapePlayers')
        const result = await scrapePlayersFunc()
        console.log(result)
    }, [])

    if (userEmail !== 'vallance.charles@gmail.com') return null
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <p>Debug Buttons</p>
                <Button onClick={scrapePlayers}>Scrape Players</Button>
            </header>
        </div>
    )
}

export default AdminDashboard
