import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Outlet } from 'react-router-dom'
import { useAppDispatch } from './store/hooks'
import { selectEmail } from './store/user/userSlice'
import { checkUser } from './store/user/userThunks'

const Authenticated: React.FC = () => {
    // TODO: Actually make sure they are authenticated
    const dispatch = useAppDispatch()
    const userEmail = useSelector(selectEmail)

    useEffect(() => {
        if (userEmail) {
            return
        }
        dispatch(checkUser())
    }, [dispatch, userEmail])

    return <Outlet />
}

export default Authenticated
