// Import the functions you need from the SDKs you need
import { FirebaseOptions, initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
    apiKey: 'AIzaSyD3rGuHiU9b0dqMqgdTuxXQz2w1etMik5U',
    authDomain: 'draftday.io',
    projectId: 'draftday-io',
    storageBucket: 'draftday-io.appspot.com',
    messagingSenderId: '244486511934',
    appId: '1:244486511934:web:e608176d9721db1ad9e06f',
    measurementId: 'G-HF92QJB1RY',
}

const setupFirebase = () => {
    // Initialize Firebase
    const app = initializeApp(firebaseConfig)
    const auth = getAuth(app)
    const firestore = getFirestore(app)
    const functions = getFunctions(app)

    if (process.env.NODE_ENV === 'development') {
        connectAuthEmulator(auth, 'http://127.0.0.1:5001')
        connectFirestoreEmulator(firestore, 'localhost', 8080)
        connectFunctionsEmulator(functions, 'localhost', 5001)
    }
}

export default setupFirebase
