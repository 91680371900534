import React, { useEffect, useState } from 'react'
import { DraftDoc } from '../../../shared/firestoreDocs'
import { Api, AthleteAllDraftStatsDto, AthleteDto, AthleteNflStatsDto } from '../../apiClients/publicApiClient'
import { useParams } from 'react-router-dom'
import { doc, getFirestore, onSnapshot } from 'firebase/firestore'
import { getApp } from 'firebase/app'
import { useSelector } from 'react-redux'
import { selectEmail } from '../../store/user/userSlice'
import Grid from '@mui/material/Unstable_Grid2'
import { Typography } from '@mui/material'

const PlayersTeam: React.FC = () => {
    return (
        <table>
            <thead>
                <tr>
                    <th>POS</th>
                    <th>Player</th>
                    <th>$</th>
                    <th>Bye</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>QB</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>RB</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>RB</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>WR</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>WR</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>TE</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>FLEX</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>D/ST</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>K</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>BE</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>BE</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>BE</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>BE</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>BE</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
                <tr>
                    <td>BE</td>
                    <td>Empty</td>
                    <td>-</td>
                    <td>-</td>
                </tr>
            </tbody>
        </table>
    )
}

interface StatsTableProps {
    athleteNflStats?: Array<AthleteNflStatsDto>
}

const StatsTable: React.FC<StatsTableProps> = ({ athleteNflStats }) => {
    if (!athleteNflStats || !athleteNflStats.length) return null

    const statsOrdered = athleteNflStats.sort((a, b) => {
        return b.seasonYear - a.seasonYear
    })

    return (
        <table>
            <thead>
                <tr>
                    <th>Year</th>
                    <th>Rank</th>
                    <th>
                        <abbr title="Games played">GP</abbr>
                    </th>
                    <th>
                        <abbr title="Games started">GS</abbr>
                    </th>
                    <th>
                        <abbr title="Passes completed">PC</abbr>
                    </th>
                    <th>
                        <abbr title="Passes attempted">PA</abbr>
                    </th>
                    <th>
                        <abbr title="Passing yards">PY</abbr>
                    </th>
                    <th>
                        <abbr title="Passing touchdowns">PTD</abbr>
                    </th>
                    <th>
                        <abbr title="Passing interceptions">INT</abbr>
                    </th>
                    <th>
                        <abbr title="Rush attempts">RA</abbr>
                    </th>
                    <th>
                        <abbr title="Rushing yards">RY</abbr>
                    </th>
                    <th>
                        <abbr title="Rushing touchdowns">RTD</abbr>
                    </th>
                    <th>
                        <abbr title="Receptions">REC</abbr>
                    </th>
                    <th>
                        <abbr title="Receiving yards">REY</abbr>
                    </th>
                    <th>
                        <abbr title="Receiving touchdowns">RETD</abbr>
                    </th>
                </tr>
            </thead>
            <tbody>
                {statsOrdered.map(x => {
                    if (x.rank === 32767) return null

                    return (
                        <tr key={x.seasonYear}>
                            <td>{x.seasonYear}</td>
                            <td>{x.rank}</td>
                            <td>{x.gamesPlayed}</td>
                            <td>{x.gamesStarted}</td>
                            <td>{x.passingCompletions}</td>
                            <td>{x.passingAttempts}</td>
                            <td>{x.passingYards}</td>
                            <td>{x.passingTouchdowns}</td>
                            <td>{x.passingInterceptions}</td>
                            <td>{x.rushingAttempts}</td>
                            <td>{x.rushingYards}</td>
                            <td>{x.rushingTouchdowns}</td>
                            <td>{x.receptions}</td>
                            <td>{x.receivingYards}</td>
                            <td>{x.receivingTouchdowns}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

interface TeamMatesListProps {
    athleteId?: string
    teamMates?: AthleteDto[]
}
const TeamMatesList: React.FC<TeamMatesListProps> = ({ athleteId, teamMates }) => {
    if (!teamMates || !teamMates.length) return null

    return (
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Pos</th>
                    <th>2022 Rank</th>
                </tr>
            </thead>
            <tbody>
                {teamMates.map(x => {
                    if (x.id === athleteId) return null

                    const rank = x.athleteNflStats?.sort((a, b) => {
                        return b.seasonYear - a.seasonYear
                    })[0].rank
                    return (
                        <tr>
                            <td>{x.headshot && <img src={x.headshot} height={40} alt={`${x.headshot} headshot`} />}</td>
                            <td>{x.fullName}</td>
                            <td>{x.position}</td>
                            <td>{rank}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const DraftDetails: React.FC = () => {
    const userEmail = useSelector(selectEmail)
    const { draftSlug } = useParams()
    const [draft, setDraft] = useState<DraftDoc | undefined>(getInitDraft())
    const [athlete, setAthlete] = useState<AthleteAllDraftStatsDto>()

    useEffect(() => {
        if (!draftSlug || !userEmail) return

        const db = getFirestore(getApp())
        const docRef = doc(db, 'users', userEmail, 'drafts', draftSlug!)

        return onSnapshot(docRef, draftDoc => {
            setDraft(draftDoc.data() as DraftDoc)
        })
    }, [userEmail, draftSlug])

    const nominatedPlayer = draft?.nominatedPlayer
    const athleteName = nominatedPlayer?.player.name
    const position = nominatedPlayer?.player.position
    useEffect(() => {
        const getAthlete = async () => {
            if (!athleteName || !position) {
                setAthlete(undefined)
                return
            }

            const publicApi = new Api({ baseUrl: 'http://localhost:9880' })
            const res = await publicApi.v1.getAllDraftStatsByName('nfl', {
                AthleteName: athleteName,
                Position: position,
            })
            console.log('RES:', res)
            if (res.ok) {
                setAthlete(res.data)
            }
        }

        getAthlete().catch(e => console.error(e))
    }, [athleteName, position])

    if (!draft) return null

    return (
        <div className="App">
            <Typography variant="h2">Draft Details</Typography>
            <Typography variant="h4">{draft.settings.leagueName}</Typography>
            <Grid container spacing={2} margin={2}>
                <Grid xs={2}>
                    {draft.nominatedPlayer && (
                        <>
                            {athlete && athlete.headshot && (
                                <img
                                    src={athlete.headshot}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    alt={`${athlete.fullName} headshot`}
                                />
                            )}
                            <Typography variant="h6">
                                {draft.nominatedPlayer.player.name} - {draft.nominatedPlayer.player.position}
                            </Typography>
                            <dl>
                                <dt>Date of birth</dt>
                                <dd>{athlete?.dateOfBirth}</dd>
                                <dt>Height</dt>
                                <dd>{athlete?.heightIn}</dd>
                                <dt>Weight (lbs)</dt>
                                <dd>{athlete?.weightLbs}</dd>
                                <dt>Years experience</dt>
                                <dd>{athlete?.yearsExperience}</dd>
                                {athlete?.draftYear && athlete.draftSelection && athlete.draftRound && (
                                    <>
                                        <dt>Draft details</dt>
                                        <dd>
                                            Year:{athlete.draftYear} Round:
                                            {athlete.draftRound} Selection:
                                            {athlete.draftSelection}
                                        </dd>
                                    </>
                                )}
                            </dl>
                        </>
                    )}
                </Grid>
                <Grid xs={8}>
                    <Typography variant="h4">Stats</Typography>
                    <StatsTable athleteNflStats={athlete?.athleteNflStats} />

                    <Typography variant="h4">Team mates</Typography>
                    <TeamMatesList athleteId={athlete?.id} teamMates={athlete?.teamMates} />
                </Grid>
                <Grid xs={2}>
                    {draft.nominatedPlayer && (
                        <>
                            <Typography variant="h6">Winning bid</Typography>
                            <Typography variant="subtitle1">
                                ${draft.nominatedPlayer.bids[0].amount} - {draft.nominatedPlayer.bids[0].fantasyTeam}
                            </Typography>
                            <p>Winning bidder's team</p>
                            <PlayersTeam />
                        </>
                    )}
                </Grid>
            </Grid>
        </div>
    )
}

export default DraftDetails

const getInitDraft = () => {
    if (process.env.NODE_ENV !== 'development') {
        return undefined
    }

    const demoData: DraftDoc = {
        seasonId: '2022',
        leagueId: '12345678',
        settings: {
            leagueName: 'Testing League',
            draftType: 'SalaryCap',
            scoringType: 'Stuff and things',
            teamsInLeague: 8,
            roster: {
                qb: 1,
                rb: 2,
                wr: 2,
                te: 1,
                dst: 1,
                k: 1,
                be: 6,
                ir: 1,
                flex: 1,
            },
        },
        fantasyTeams: [
            { name: 'Alicetown Lions', isUserTeam: false },
            { name: "Blitzer's", isUserTeam: false },
            { name: 'Brisbane Dolphins', isUserTeam: false },
            { name: 'Woke All Stars', isUserTeam: false },
            { name: 'Gisbourne Jets', isUserTeam: false },
            { name: 'Waikanae Seagulls', isUserTeam: true },
            { name: 'River King Rams', isUserTeam: false },
            { name: 'Golden Bay Giants', isUserTeam: false },
            { name: 'Poon Town Putts', isUserTeam: false },
        ],
        drafts: [
            {
                player: {
                    name: 'Cooper Kupp',
                    position: 'WR',
                    team: 'LAR',
                },
                fantasyTeam: "Blitzer's",
                draftPrice: 74,
            },
            {
                player: {
                    name: 'Christian McCaffrey',
                    position: 'RB',
                    team: 'Car',
                },
                fantasyTeam: 'River King Rams',
                draftPrice: 57,
            },
            {
                player: {
                    name: 'Jonathan Taylor',
                    position: 'RB',
                    team: 'Ind',
                },
                fantasyTeam: 'Waikanae Seagulls',
                draftPrice: 63,
            },
        ],
        nominatedPlayer: {
            player: {
                name: 'Justin Jefferson',
                position: 'WR',
                team: 'Min',
            },
            bids: [
                { amount: 54, fantasyTeam: 'Golden Bay Giants' },
                { amount: 49, fantasyTeam: 'Gisborne Jets' },
                { amount: 35, fantasyTeam: 'Waikanae Seagulls' },
                { amount: 1, fantasyTeam: 'Golden Bay Giants' },
            ],
        },
    }

    return demoData
}
