import App from './App'
import { createBrowserRouter } from 'react-router-dom'
import Authenticated from './Authenticated'
import DraftsList from './routes/drafts/DraftsList'
import DraftDetails from './routes/drafts/DraftDetails'
import AdminDashboard from './routes/admin/AdminDashboard'

export default createBrowserRouter([
    {
        path: '/',
        element: <App />,
    },
    {
        path: '/app',
        element: <Authenticated />,
        children: [
            {
                path: 'admin',
                element: <AdminDashboard />,
            },
            {
                path: 'drafts',
                element: <DraftsList />,
            },
            {
                path: 'drafts/:draftSlug',
                element: <DraftDetails />,
            },
        ],
    },
])
